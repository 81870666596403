<template>
  <b-container class="authPages">
    <b-row>
      <b-col lg="8" offset-lg="2">
        <article class="single-blog">
          <div class="post-content">
            <div>
              <h3 class="mt-2">Prijava</h3>

              <b-alert variant="danger" :show="error.length > 0"
                >{{ error }}
              </b-alert>

              <b-alert
                variant="info"
                :show="$route.params.justRegistered === 'true'"
              >
                Zahvaljujemo se vam registracijo!<br />
                Odprite svoj elektronski predal in kliknite potrditveno
                povezavo, da aktivirate uporabniški račun.
              </b-alert>

              <b-form class="form-horizontal contact-form">
                <b-form-group>
                  <b-input
                    v-model="username"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="uporabniško ime (email)"
                    required=""
                  />
                </b-form-group>
                <b-form-group style="position: relative;">
                  <b-input
                    v-model="password"
                    :type="passwordVisible ? 'text' : 'password'"
                    id="password"
                    name="password"
                    placeholder="geslo"
                    required=""
                  />
                  <i
                    class="fa"
                    :class="this.passwordVisible ? 'fa-eye-slash' : 'fa-eye'"
                    style="font-size: 1.3em; position: absolute; right: 10px; top: 13px; color: #6d5a3f; cursor: pointer;"
                    :style="
                      this.passwordVisible ? 'right: 10px;' : 'right: 11.5px;'
                    "
                    @click="
                      () => {
                        this.passwordVisible = !this.passwordVisible;
                      }
                    "
                  ></i>
                </b-form-group>
                <b-button
                  type="submit"
                  class="btn send-btn mb-4 mt-2"
                  @click.prevent.stop="login"
                  >PRIJAVA
                </b-button>

                <div class="mt-2">
                  <a
                    class="btn btn-link"
                    @click.prevent.stop="
                      () => {
                        $router.push({ name: 'Register' });
                      }
                    "
                    >Še niste registrirani?</a
                  >
                </div>
                <div class="mt-2">
                  <a
                    class="btn btn-link"
                    @click.prevent.stop="
                      () => {
                        $router.push({ name: 'ForgottenPassword' });
                      }
                    "
                    >Ste pozabili geslo oz. ga želite spremeniti?</a
                  >
                </div>
              </b-form>
            </div>
          </div>
        </article>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase";
import { db } from "../../main";

export default {
  name: "SignIn",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      error: "",
      user: {},
      passwordVisible: false
    };
  },
  beforeCreate() {
    const selectedLang = localStorage.getItem('zapisi_spomina_language');
    if(selectedLang !== null) {
      if(selectedLang === 'EN' && !this.$route.fullPath.includes('/en')) {
        this.$router.push({name: 'EN_' + this.$route.name}, this.$route.params);
      } else if(selectedLang === 'SI' && this.$route.fullPath.includes('/en')) {
        this.$router.push({name: this.$route.name.split('EN_')[1]}, this.$route.params);
      }
    }
  },
  mounted() {
    console.log("$route", this.$route);
  },
  methods: {
    login() {
      const _this = this;
      console.log("login", _this);

      firebase
        .auth()
        .signInWithEmailAndPassword(_this.username, _this.password)
        .then(data => {
          console.log("data", data);

          if (data && data.user && !data.user.emailVerified) {
            _this.error =
              "Pred prijavo morate potrditi vaš e-poštni naslov. To storite s klikom na povezavo, ki ste je ob registraciji prejeli v vaš e-poštni predal.";
            return false;
          }

          db.collection("users")
            .where("email", "==", _this.username)
            .get()
            .then(querySnapshot => {
              if (!querySnapshot.empty) {
                _this.updateUser(data, querySnapshot.docs[0].id);
              } else {
                _this.error =
                  "Prijava s posredovanimi podatki ni mogoča!";
                console.error("User not found!");
              }
            });
        })
        .catch(err => {
          console.log("error", err);
          _this.error = "Prijava s posredovanimi podatki ni mogoča!";
        });
    },

      updateUser(data, recordId) {
      const _this = this;

      console.log("recordId", recordId);

      db.collection("users")
        .doc(recordId)
        .update({
          lastLoginAt: new Date()
        })
        .then(function() {
          console.log("Record updated");
          _this.$router.push({ name: "MyPosts" });
        })
        .catch(function(error) {
          console.error("Error adding Record: ", error);
        });
    }
  }
};
</script>
